<template>
  <div class="gallery-image">
    <label class="form-control-label"> {{ label }} </label>
    <div class="card-img">
      <div class="card-img principal" v-if="gallery.length > 0">
        <p>{{ $t("COMMON.MAIN_PICTURE") }}</p>
        <div class="principal-image">
          <img :src="gallery[0]" alt="image" />
        </div>
      </div>
      <div
        v-for="(image, key) in gallery.slice(1)"
        :key="key"
        class="gallery-image-container card-img"
      >
        <img :src="`${image}`" class="gallery-image" />
        <base-button
          type="button"
          class="gallery-image-remove-btn btn btn-sm btn-danger"
          @click="removeImage(key)"
          v-if="!disabled"
        >
          <span>
            <i class="fal fa-trash-alt"></i>
          </span>
        </base-button>
        <el-tooltip
          v-if="!disabled"
          content="Choisir comme photo principale"
          placement="top"
        >
          <base-button
            type="button"
            class="principal-button"
            @click="setAsMainPicture(key + 1)"
          >
            <i class="fal fa-images"></i>
          </base-button>
        </el-tooltip>
      </div>

      <base-button
        v-if="!disabled"
        type="button"
        class="btn btn-sm mr-2 mb-2 gallery-add-image-btn"
      >
        <label :for="`imageInput-${refKey}`">
          <i v-if="loading" class="fas fa-spinner fa-spin"></i>
          <img v-else src="/img/add-image.svg" class="argon-image" />
        </label>
        <input
          :id="`imageInput-${refKey}`"
          :ref="`imageInput-${refKey}`"
          accept="image/*"
          type="file"
          multiple
          style="display: none"
          @input="onFileChange"
        />
      </base-button>
    </div>
  </div>
</template>

<script>
import { uniqueId } from "lodash";
import requestErrorMixin from "@/mixins/request-error-mixin";

export default {
  name: "gallery-selector",

  mixins: [requestErrorMixin],

  props: {
    defaultGallery: {
      type: Array,
      default: null,
      description: "Default gallery to diplay, should be model value",
    },
    label: {
      type: String,
      default: null,
      description: "Label of the field",
    },
    ressource_name: {
      type: String,
      default: null,
      description:
        "Ressource name to be uploaded with, must be allowed in the api",
    },
    ressouce_id: {
      type: String,
      default: null,
      description:
        "Ressource id to be uploaded with, must be allowed in the api",
    },
    field: {
      type: String,
      default: null,
      description:
        "Ressource field to be uploaded with, must be allowed in the api",
    },
    disabled: {
      type: Boolean,
      default: false,
      description: "Disable the input",
    },
  },

  setup() {},

  data() {
    const refKey = uniqueId([]);
    return {
      gallery: this.defaultGallery ? this.defaultGallery : [],
      loading: false,
      refKey: refKey,
    };
  },

  methods: {
    onFileChange(e) {
      let files = e.target.files || e.dataTransfer.files;

      if (!files.length) return;

      Array.from(files).forEach((f) => this.uploadImage(f));
    },

    async uploadImage(file) {
      this.loading = true;
      if (file) {
        try {
          await this.$store.dispatch("files/upload", {
            image: file,
            ressource_name: this.ressource_name,
            ressource_id: this.ressource_id,
            field: this.field,
          });
          const image_url = await this.$store.getters["files/url"];
          this.gallery = [...this.gallery, image_url];
          this.$emit("galleryChanged", this.gallery);
          this.$refs[`imageInput-${this.refKey}`].value = null;
          this.loading = false;
        } catch (error) {
          this.showRequestError(error);
          this.$notify({
            type: "danger",
            message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
          });
          this.loading = false;
        }
      }
    },

    removeImage(key) {
      this.gallery = this.gallery.filter((_, idx) => key !== idx);
      this.$emit("galleryChanged", this.gallery);
    },

    setAsMainPicture(index) {
      this.moveElementToFirst(this.gallery, index);
      this.$emit("galleryChanged", this.gallery);
    },

    moveElementToFirst(arr, index) {
      if (index < 0 || index >= arr.length) {
        return arr;
      }
      const element = arr.splice(index, 1)[0];
      arr.unshift(element);
      return arr;
    },
  },

  watch: {
    defaultGallery(defaultGallery) {
      this.gallery = defaultGallery;
    },
  },
};
</script>

<style lang="scss" scoped>
.gallery-image {
  > .form-control-label {
    display: none;
  }
  .card-img {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-end;
    margin: 0;
    &.principal {
      width: 125px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      margin: 0 20px 0 0;
      p {
        margin: 0 0 12px 0 !important;
        font-size: 14px;
      }
      .principal-image {
        width: 125px;
        height: 125px;
        margin: 0;
        border-radius: 26px;
        border: solid 4px #930019;
        img {
          width: 100%;
          height: 100%;
          border-radius: 22px;
          object-fit: cover;
          object-position: center;
        }
      }
    }
    .gallery-image-container {
      width: 125px;
      height: 125px;
      border: 1px solid #f2f2f2;
      display: flex;
      position: relative;
      border-radius: 30px;
      margin: 0 20px 0 0;
      &:hover {
        .gallery-image-remove-btn {
          opacity: 1;
          visibility: visible;
        }
      }
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
        padding: 0;
        border-radius: 22px;
      }
      .gallery-image-remove-btn {
        width: 100%;
        height: 100%;
        position: absolute;
        left: 50%;
        top: 50%;
        border-radius: 30px;
        transform: translate(-50%, -50%);
        background-color: rgb(255, 0, 0, 0.6);
        margin: 0 !important;
        border: none;
        opacity: 0;
        padding: 0;
        cursor: pointer;
        visibility: hidden;
        -webkit-transition: all 0.3s ease-in-out;
        -moz-transition: all 0.3s ease-in-out;
        transition: all 0.3s ease-in-out;
        i {
          font-size: 30px;
          color: #fff;
        }
      }
      .principal-button {
        width: 34px;
        height: 34px;
        background-color: #000;
        padding: 0;
        border-radius: 30px;
        position: absolute;
        bottom: 0;
        right: 0;
        /* opacity: 0;
        visibility: hidden; */
        i {
          color: #ffeb00;
        }
      }
    }
    .gallery-image {
      width: 100%;
      height: 100%;
    }
    .gallery-add-image-btn {
      width: 125px;
      height: 125px;
      border-radius: 30px;
      margin: 0 !important;
      padding: 0;
      background-color: #f2f2f2;
      box-shadow: none;
      label {
        width: 100%;
        height: 100%;
        border-radius: 50%;
        display: flex;
        flex-direction: row;
        justify-items: center;
        align-items: center;
        margin: 0;
        cursor: pointer;
        .fa-spinner {
          position: absolute;
          top: calc(50% - 10px);
          left: calc(50% - 10px);
          font-size: 24px;
          transform: translate(-50%, -50%);
          padding: 0;
        }
        img {
          border: none;
          padding: 0;
          width: 43px;
          height: auto;
          margin: 0 auto;
        }
      }
    }
  }
}
</style>
